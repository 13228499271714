import React, { useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import logo from '../assets/images/galaxis.svg';
import logoWhite from '../assets/images/Galaxis_for_black.png';
import email from '../assets/images/social/email.svg';
import twitter from '../assets/images/social/twitter.svg';
import discord from '../assets/images/social/discord.svg';
import EmailPopup from './EmailPopup';
import { ModeCtx } from '../context/modeCtx';
import { useContext } from 'react';
import { DateTime } from 'luxon';

const sx = {
  root: {
    position: 'absolute',
    height: '110px',
    bottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialContainer: {
    maxHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    mb: '10px',
    gap: '10px',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  minuscule: {
    mt: '-1px',
    fontSize: '12px',
    fontFamily: 'poppins',
    color: '#020002',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '10px',
  },
};

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modeCtx = useContext(ModeCtx);
  return (
    <>
      <Box sx={sx.root}>
        <Box sx={sx.socialContainer}>
          <a href='https://discord.com/invite/galaxis' target='_blank' rel='noopener noreferrer'>
            <Box>
              <img src={discord} style={{ width: '22px', maxHeight: '18px' }} alt='discord' />
            </Box>
          </a>
          <a href='https://twitter.com/galaxisxyz' target='_blank' rel='noopener noreferrer'>
            <Box>
              <img src={twitter} style={{ width: '22px', maxHeight: '18px' }} alt='twitter' />
            </Box>
          </a>
          <a rel='noopener noreferrer'>
            <Box onClick={() => setModalIsOpen(true)} style={{ cursor: 'pointer' }}>
              <img src={email} style={{ width: '22px', maxHeight: '18px' }} alt='twitter' />
            </Box>
          </a>
        </Box>
        <Box sx={sx.logo}>
          {modeCtx.lightTheme ? (
            <img src={logo} style={{ width: '73px', marginRight: '16px' }} alt='logo' />
          ) : (
            <img src={logoWhite} style={{ width: '73px', marginRight: '16px' }} alt='logo' />
          )}
          <Typography sx={sx.minuscule}>All Rights Reserved 2021-{DateTime.now().year}</Typography>
        </Box>
      </Box>
      <Modal
        onBackdropClick={() => {
          setModalIsOpen(false);
        }}
        sx={sx.modal}
        open={modalIsOpen}
      >
        <EmailPopup
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
export default Footer;
